/* .main-container{
    padding-top: 10vw;

}
@media (max-width: 950px) {
    .main-container{
        padding-top: 50vw;
    }
  }
@media (max-width: 500px) {
    .main-container{
        padding-top: 40vw;
    }
  }
.blob2{
    aspect-ratio: 960/540;
    background-image: url('./images/waveup.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
.intro{
    color:aliceblue;
    padding: 10%;
}
.intro li{
    list-style: none;
    font-size: x-large;
}
.intro2 img{
    border-top-left-radius: 50% 50%; border-top-right-radius: 50% 50%; border-bottom-right-radius: 50% 50%; border-bottom-left-radius: 50% 50%; 
    width: 80%;
} */
.C1{
    color:black;
}
.C2{
    color:#0066FF;
}