.slider {
	background-color: #ffffff;
	background-size: cover;
	height: 130px;
	position: relative;
	width: 100%;
	display: grid;
	place-items: center;
	overflow: hidden;
	margin-bottom: 20px;
  
	&::before,
	&::after {
	  position: absolute;
	  background-image: linear-gradient(to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
	  content: '';
	  height: 100%;
	  width: 25%;
	  z-index: 2;
	  pointer-events: none;
	}
  
	&::before {
	  left: 0;
	  top: 0;
	}
  
	&::after {
	  right: 0;
	  top: 0;
	  transform: rotateZ(180deg);
	}
  
	.slide-track2 {
	  width: calc(270px * 20); // 10 slides duplicated
	  display: flex;
	  animation: scroll2 20s linear infinite;
	  justify-content: space-between;
	  position: relative;
	  left: 0;
	  right: 0;
	  margin: 0 auto;
  
	  &:hover {
		animation-play-state: paused;
	  }
	}
  
	.slide {
	  width: 270px;
	  height: 60px;
	  user-select: none;
	  display: grid;
	  place-items: center;
	  justify-self: center;
	  transition: 0.5s;
	  cursor: pointer;
  
	  &:hover {
		transform: scale(0.8);
	  }
	}
  
	.logo {
		max-width: 300px;
		max-height: 100px;
		object-fit: contain;
	}
  }
  
  @keyframes scroll2 {
	0% {
	  transform: translateX(0);
	}
	100% {
	  transform: translateX(calc(-270px * 10)); // Shift by 10 slides width
	}
  }
  
  @media (max-width: 700px) {
	.slider{
		margin-bottom: -2px;
	}
  }