.main-container404{
    min-height: 100vh;
    padding-top:15vh;
    color:#006ff0;
    text-align:center;
    /* background-image: url(../../Assets/Images/bg.png); */
}

/* .main-container404 h1,.main-container404 h4{
    text-align: center;
    color: blue;
} */
.main-container404 img{
    width: auto;
    height: auto ;
    max-height: 100%;
}
.row{
    margin-right: 0 !important; 
}

