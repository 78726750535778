 .contacts{
  user-select:text;
  color: #121212;
  min-height: fit-content;
  padding-right: 20px;
  padding-left: 20px;
  max-width: 100vw;
 }
 .firstNav{
  margin-top: 3vh;
 }
 .Mnav {
    display: inline-block;
    position: relative;
  }

  .Mnav:after {
    content: '';
    position: absolute;
    width: 100%;
    transform: scaleX(0);
    height: 2px;
    bottom: 0;
    left: 0;
    color:  #0066FF ;
    background-color: #0066FF;
    transform-origin: bottom right;
    transition: transform 0.25s ease-out;
  }

  .Mnav:hover:after {
    transform: scaleX(1);
    transform-origin: bottom left;
  }
  .Mnav:current {
    color: #0066FF !important;
  }

  .NavCont{
    user-select: none;

  }

#navbar{
  background: rgba(255, 255, 255, 0.9);
  color:black;
  
}
@media (max-width:500px) {
  #navbar{
    background: rgba(255, 255, 255, 1);
    color:black;
    border:rgba(128,128,128,0.5) solid;
    margin-top: 6vh;
  }
  .spacer-nav{
    width: 0rem;
  }
}
.icon{
  color: #0066FF;
  /* font-size: 30px !important; */
}
.icon:hover{
  color: #0eaaf8;
}
.branding{
  margin-left:0px;
}