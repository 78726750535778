
  
  .iframely-embed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 50;
    background: white;
  }
  
  .part1about {
    z-index: 100;
    padding-top: 10vh;
    display: grid;
    grid-template-columns: 1fr 2fr;
    align-items: center;
    padding-bottom: 4vmin;
    height: 100vh;
    width: 100%;
    background: white;
    color: #3a3535;
  }

 .part2about{
  min-height: 90vh;
  background-image: url("./images/bg2.webp");
  background-size:cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

 .part2-div {
  min-height: 50vh;
  max-width: 70vw;
  min-width: 70vw;
  background: rgba($color: white, $alpha: 0.99);
  border: 2px solid #121212 ;
  border-radius: 0px 10px 10px 0px;
  padding: 2rem;
}
.part2-div:nth-child(2) {
  min-height: 50vh;
  max-width: 70vw;
  min-width: 70vw;
  background: rgba($color: white, $alpha: 0.99);
  border: 2px solid #121212;
  border-radius: 10px 0px 0px 10px;
  padding: 2rem;
}
.part2-in li{
  font-size: x-large;
}

@media (max-width: 950px) {
  .part2-div {
    min-height: 50vh;
    min-width: 100vw !important;
    background: white;
  
  }
}
@font-face {
  font-family: 'Prata';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("./fonts/6xKhdSpbNNCT-sWPCm4.woff2") format('woff2');
  unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
  font-display: swap;

}
  
  .title {
    padding-left: 1em;
    grid-column: 1 / -1;
    grid-row: 1;
  
    font-family: "Prata", serif;
    font-size: 8vw;
    width: 100%;
    z-index: 2;
  
  
    > .title-inner {
      display: inline-block;
    }
  }
  
  @keyframes text-clip {
    from {
      clip-path: polygon(0% 100%, 100% 100%, 100% 100%, 0% 100%);
    }
    to {
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }
  }
  
  @keyframes outer-left {
    from {
      transform: translateX(50%);
    }
    to {
      transform: none;
    }
  }
  
  @keyframes inner-left {
    from {
      transform: translateX(-50%);
    }
    to {
      transform: none;
    }
  }
  
  .lets,
  .share,
  .succ {
    animation: outer-left 1s 1s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  

  
  .title-inner {
    display: inline-block;
    animation: inner-left 1s 1s ease both;
  }
  
  .lets-inner {
    display: inline-block;
    animation: inner-left 1s 1s ease both,
      text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  
  .share-inner {
    animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  
  .title {
    animation: outer-left 1s 1s ease both;
  }
  
  .lets {
    > .lets-inner {
      display: inline-block;
    }
  }
  
  .share {
    display: inline-block;
  }
  .succ{
    color:white;
    display: inline-block;
  }
  .succ-inner {
    animation: text-clip 1s 0s cubic-bezier(0.5, 0, 0.1, 1) both;
  }
  
  .image {
    grid-row: 1;
    grid-column: 2;
    margin-left: -2rem;
    opacity: 0.7;
  
    animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 1s backwards;
  
    @keyframes image-in {
      from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      }
      to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  
    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }

  .image {
    grid-row: 1;
    grid-column: 2;
    margin-left: -2rem;
    opacity: 0.7;
  
    animation: image-in 1s cubic-bezier(0.5, 0, 0.1, 1) 1s backwards;
  
    @keyframes image-in {
      from {
        clip-path: polygon(0 0, 100% 0, 100% 0, 0 0);
      }
      to {
        clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
      }
    }
  
  }
  .A1{
    padding-right:10 rem;
  }