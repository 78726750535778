@font-face {
  font-family: Anton-Regular;
  src: url("../Fonts/Handel Gothic Regular.woff");
  font-display: swap;
  font-variant-alternates: historical-forms !important;
}

.homecontainer {
  margin-top: 48vh;
  background-image: url("../Images/ser.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  max-width: 100vw;
  user-select: none;
}
.centeredhome {
  margin-bottom: 3rem;
  user-select: none;
  margin: 0;
  padding: 0;
  border-block: solid;
  border-color: #0066ff;
  -webkit-text-stroke: 2px #121212;
  font-size: 6vw;
  font-family: Anton-Regular, sans-serif;
  position: absolute !important;
  top: 30% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  margin: 0;
  background: url("../Images/stacked-waves-haikei.svg") no-repeat;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  animation: moveBg 15s linear infinite;
  overflow: hidden;
}
.text-transition {
  color: #0066ff;
}
@media only screen and (min-width: 600px) {
  .spacer {
    margin: -5px;
  }
  .spacer3 {
    margin: -5px;
  }
  .partners {
    margin-bottom: -2px;
  }
}
@media (max-width: 768px) {
  #homsec {
    margin-bottom: 2rem !important;
  }
  .homecontainer {
    margin-top: 35vh;
  }
  .centeredhome {
    border: none;
    font-size: 10vw;
    padding-bottom: 5rem;
    padding-left: 5rem !important;
  }
  #mainabout1 {
    flex-shrink: inherit !important;
  }
  .spacer {
    margin: -2px;
  }
  .spacer3 {
    margin: -2px;
  }
  .partners {
    margin-bottom: -2px;
  }
}
@keyframes moveBg {
  0% {
    background-position: 0% 30%;
  }
  100% {
    background-position: 100% 50%;
  }
}

#homesec {
  min-height: fit-content;
  user-select: none;
  position: relative;
}

#homesec nav {
  min-height: fit-content;
  user-select: none;
  position: relative;
  max-height: fit-content;
}
#homeabout {
  background-color: #0066ff;
  background-size: cover;
  color: #0066ff;
  min-height: 50vh;
  max-height: fit-content;
  user-select: none;
  position: relative;
  /* max-width: 100vw; */
  min-width: 100vw;
  font-size: x-large;
  overflow: hidden;
}

/* #mainabout2{
  border: solid black;
  
  border-radius:76% 24% 57% 43% / 30% 35% 65% 70% ;
  background-image: url("./man.webp");
  box-shadow: 0px -2px 2px rgba(0, 102, 255,0.6);
  background-size:cover;
  background-repeat: no-repeat;
  background-position: center;
} */
.aboutpic {
  width: 35vw;
}
.aboutpara {
  line-height: 2;
  font-size: x-large;
}
.blockquote {
  border-left: 5px solid;
  margin-left: 0;
  padding: 1rem 0 1rem 2rem;
  font-size: 2rem;
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(35deg, #000000, #000000);
  p {
    margin: 0;
  }
}
#mainabout1 {
  color: aliceblue;
  margin-right: 10vw !important;
  margin-left: 10vw !important;
  margin-left: 10vw !important;
  margin-top: 5vw !important;
}

.button {
  --background-color: white;
  --border-color: linear-gradient(to bottom right, #0066ff, blue);
  --border-width: 0.5em;
  --edge-size: 0.5em;
  cursor: pointer;
  background: var(--background-color);
  margin: 5px;
  padding: 0.5em 1.5em;
  border: 0;
  position: relative;
  isolation: isolate;
  display: inline-grid;
  place-content: center;
  clip-path: polygon(
    var(--edge-size) 0%,
    100% 0,
    100% calc(100% - var(--edge-size)),
    calc(100% - var(--edge-size)) 100%,
    0 100%,
    0% var(--edge-size)
  );
  transition: color 250ms;
}

button::before {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--border-color);
  z-index: -2;
}

button::after {
  content: "";
  position: absolute;
  inset: 0;
  background: var(--background-color);
  z-index: -1;
  clip-path: polygon(
    var(--border-width) calc(var(--edge-size) + var(--border-width) * 0.5),
    calc(var(--edge-size) + var(--border-width) * 0.5) var(--border-width),
    calc(100% - var(--border-width)) var(--border-width),
    calc(100% - var(--border-width))
      calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5)),
    calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width)),
    calc(var(--border-width)) calc(100% - var(--border-width))
  );
  transition: clip-path 500ms;
}

button:hover {
  color: white !important;
}

button:hover::after {
  clip-path: polygon(
    calc(100% - var(--border-width))
      calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5)),
    calc(100% - var(--border-width)) var(--border-width),
    calc(100% - var(--border-width)) var(--border-width),
    calc(100% - var(--border-width))
      calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5)),
    calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width)),
    calc(100% - calc(var(--edge-size) + var(--border-width) * 0.5))
      calc(100% - var(--border-width))
  );
}
#homesecpart {
  width: 100vw;
  background-color: #ffffff;
  background-size: cover;
}
.partners {
  margin: 0;
  padding-bottom: 8px;
  padding-top: 8px;
  text-align: center;
  background-color: #ffffff;
  background-size: cover;
  font-family: "Anton-Regular";
  color: #ff9900;
  user-select: none;
}
.category {
  text-align: center;
  text-transform: capitalize;
}
.spacer {
  aspect-ratio: 960/300;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.layer1 {
  background-image: url("../Images/wave-haikei.svg");
}
.spacer2 {
  aspect-ratio: 900/300;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.spacer3 {
  aspect-ratio: 900/100;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.spacer4 {
  aspect-ratio: 900/50;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.layer2 {
  background-image: url("../Images/wave-haikei9.svg");
}
.layer3 {
  background-image: url("../Images/wave-haikei6.svg");
}
.layer4 {
  background-image: url("../Images/wave-haikei5.svg");
}
.layer5 {
  background-image: url("../Images/wave-haikei12.svg");
}
.ProjectsCont {
  background-color: white;
  min-height: 60vh;
}

.project {
  background: #ffffff;
}

.project img {
  display: block;
  width: 200px;
  height: 100px;
  object-fit: contain;
  mix-blend-mode: multiply;
}
.project a {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  list-style-type: none;
  margin: 0;
  padding-left: 15vw;
  padding-right: 15vw;
}

.project {
  flex: 0 0 50%;
  padding: 5px;
  padding-left: 15px;
  padding-right: 15px;
  -webkit-transform: scale(1);
  transform: scale(1);
  -webkit-transition: 0.3s ease-in-out;
  transition: 0.3s ease-in-out;
}

.projects li:hover {
  -webkit-transform: scale(0.8);
  -webkit-transition: 0.3s ease-in-out;

  transform: scale(0.8);
  transition: 0.3s ease-in-out;
}
.projects li:hover::after {
  -webkit-transform: scale(1);
  -webkit-transition: 0.3s ease-out;

  transform: scale(1);
  transition: 0.3s ease-out;
}

@media (min-width: 700px) {
  .project {
    flex: 0 0 33.33%;
    padding-left: 5px;
    padding-right: 5px;
  }
}

@media (min-width: 1100px) {
  .project {
    flex: 0 0 25%;
    padding-left: 15px;
    padding-right: 15px;
  }
}

.contact {
  min-height: 10vh;
  padding-left: 30px;
  padding-right: 30px;
}
.contact p {
  padding-left: 15px;
  padding-right: 15px;
  color: black;
  border: 2px solid white;
  border-radius: 30px;
  background-color: rgba(255, 255, 255, 0.5);
}
.L1 {
  color: #ff9900;
  font-family: Lucida Console, Courier New, monospace;
  margin-top: 10px;
}
.L2 {
  color: #121212;
}
.LP {
  width: 18rem !important;
  margin-right: 2rem;
  margin-left: 2rem;
  margin-bottom: 0.5rem;
}
.L3 {
  border-radius: 2.5px;
  margin-top: 1rem;
  min-height: 262px;
  object-fit: fit;
}
.L4 {
  color: red;
  overflow-x: hidden;
}

.ds {
  font-size: 4.5rem;
  margin: 0;
  padding-bottom: 8px;
  text-align: center;
  font-family: Arial, sans-serif;
  background-size: cover;
  font-family: "Anton-Regular";
  color: floralwhite;
}
.vertical-buttons {
  position: absolute;
  left: 5%;
  margin-top: -5%;
}
.filter {
  border: 1px solid #0066ff;
  height: 40px;
  border-radius: 50%;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.3s, background-color 0.3s;
  color: inherit;
  background-color: #f0f0f0;
}

.filter:hover {
  background-color: #0066ff;
}
.filter.active {
  color: #f0f0f0;
  background-color: #0066ff;
}
.filter-icon {
  color: inherit;
}

.vertical-buttons-all {
  position: absolute;
  left: 5%;
  margin-top: 5%;
}
