.big-container{
    width: 100vw;
    margin-top: 15vh !important;
    min-height: 100vh;
  }
  .form{
    margin: 2rem;
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    text-align: left;
    padding-left: 5rem;

  }
  .form-border{
    margin: 2vw;
    border-radius: 1vw;
    border-color: aliceblue;
    display: block;
    background-image: url("./images/formbg.webp");
    background-color: whitesmoke;
    background-repeat: no-repeat;
    background-position:right;
    background-size: contain;
  }
@media (max-width: 768px) {
  .form{
    padding-left: 1rem;
  }
    .blockquote2 {
        border-left: 5px solid #f5f5f5 !important;
    }
    .map iframe{
      border-radius: 1rem 1rem 1rem 1rem !important;
  }
  .form-border{
    margin: 0;
    background: none;
    background-color: whitesmoke;
  }
  .big-container{
    justify-content: center;
    align-content: center;
  }
  .map-location{
    margin-left: 0px !important;
  }
  .map{
    padding-right: 0px !important;
    margin-top:1rem !important;
  }
  }
  .blockquote2 {
    border-left: 5px solid;
    margin-left: 1rem;
    padding: 1rem 0 1rem 2rem;
    font-size: 1.5rem;
    color:#f5f5f5;
    /* background-image: linear-gradient(
      35deg,
      #0066ff, 
      #0066f9   
    ); */
    p {
      margin: 0;
    }
  }
  .bq-header{
    /* color: #0f0f0f; */
    margin-left: 1rem;
    color: #f5f5f5;
    font-family: 'Poppins', sans-serif;
  }

.map{
    /* background-color: white; */
}
.map iframe{
    position: relative;
    height:100%;
    width: 100%;
    border: solid #0066f9;
    border-radius: 1rem 1rem 0rem 0rem;
    /* border-radius:  10px 100px / 120px; */
    border-width: 0.5px;
    margin-left: 1rem;
}
.ccontacts{
    padding: 10%;
    color: #f5f5f5 !important; 

}

.email{
    padding: 5%;
    /* background-color: aliceblue; */
}

.phone, .mail, .address {
  color: #f5f5f5;
  text-decoration: none ;
}
.phone:hover, .mail:hover, .address:hover {
  color: azure;
  text-shadow: 0 0 3px #121212;
}

.me-3{
  color: crimson;
}