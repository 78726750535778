.projects-container {
  min-height: 85vh;
  margin-top: 20vh;
  color: #f5f5f5;
}

.project-icons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.project-icon {
  cursor: pointer;
  margin: 10px 0;
  padding: 10px;
  transition: transform 0.3s;
}

.project-icon.active {
  transform: scale(1.2);
}

.project-logo {
  width: 100px;
  height: auto;
  background-color: rgba(0, 255, 255, 0.4);
  background-size: contain;
  border-radius: 25px;
  padding: 20px;
  margin: -20px;
  margin-top: 10px;
}

.project-logo:hover {
  transform: scale(1.1);
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.project-carousel {
  width: 100%;
  height: auto;
  overflow: hidden;
  padding: 0 !important;
  --border-angle: 0turn;
  --main-bg: conic-gradient(
    from var(--border-angle),
    #213,
    #112 5%,
    #112 60%,
    #213 95%
  );
  border: solid 5px transparent;
  border-radius: 2em;
  --gradient-border: conic-gradient(from var(--border-angle), transparent 25%, #f5f5f5, #3b8aff 99%, transparent);
  background: 
    var(--main-bg) padding-box,
    var(--gradient-border) border-box, 
    var(--main-bg) border-box;
  background-position: center center;
  animation: bg-spin 10s linear infinite;
}

@keyframes bg-spin {
  to {
    --border-angle: 1turn;
  }
}

@property --border-angle {
  syntax: "<angle>";
  inherits: true;
  initial-value: 0turn;
}

.project-image-container {
  position: relative;
  width: 100%;
  height: 600px;
}

.project-image-container img,
.project-image-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.image-counter {
  position: absolute;
  bottom: 10px;
  right: 10px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  z-index: 1;
}

.carousel-caption {
  font-size: large;
}

.projects-row {
  justify-content: center;
}

.slick-dots {
  bottom: 10px;
  li {
    button {
      &::before {
        color: white;
        font-size: 12px;
      }
    }
    &.slick-active {
      button {
        &::before {
          color: white;
        }
      }
    }
  }
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(107, 114, 128, 0.9);
  z-index: 1000;
}

.loading-logo {
  width: 200px;
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    opacity: 0.5;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

.hidden {
  display: none;
}
