.container-sol{
    min-height: 100vh;
    padding: 2rem;
    margin-top:15vh;
    color: azure;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    max-width: 100vw;
}
.S1{
    object-fit:contain;
}
@media (max-width: 768px) {
    .container-sol{
        margin-top: 10vh;
        max-width: 100vw;
    }
    .card{


    }
    .cooling, .power, .it, .raised-floor{
        justify-content: center;
        margin: 0 !important;
    }
}

/* .cooling{
    background-color: rgba(255,255,255,0.2);
    text-align: center;
}
.power{
    background-color: rgba(255,255,255,0.2);
    text-align: center;


}
.it{
    background-color: rgba(255,255,255,0.2);
    text-align: center;


}
.raised-floor{
    background-color: rgba(255,255,255,0.2);
    text-align: center;
} */
.header {
    text-align: center;
    display: flex;
    align-items: center;
    text-align: center;
    font-family: Verdana, Geneva, Tahoma, sans-serif;
  }
  
  .header::before,
  .header::after {
    content: '';
    flex: 1;
    border-bottom: 3px solid #000;
  }
  
  .header:not(:empty)::before {
    margin-right: .25em;
  }
  
  .header:not(:empty)::after {
    margin-left: .25em;
  }
  .card{
    background-color: whitesmoke !important;
  }
  .card-sol-text{
    white-space:pre-wrap;
  }