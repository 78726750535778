.secondary-container{
    padding-top: 10vw;

}
@media (max-width: 950px) {
    .secondary-container{
        padding-top: 50vw;
    }
  }
@media (max-width: 500px) {
    .secondary-container{
        padding-top: 40vw;
    }
  }


.aheader h2{
    color:black ;
}
.aheader:hover h2{
    color: #0066FF ;
}
.service-tab{
    padding:10px;
}
.service-tab li{
    font-size:x-large;
}


@property --angle {
    syntax: '<angle>';
    initial-value: 90deg;
    inherits: true;
  }
  
  @property --gradX {
    syntax: '<percentage>';
    initial-value: 50%;
    inherits: true;
  }
  
  @property --gradY {
    syntax: '<percentage>';
    initial-value: 0%;
    inherits: true;
  }
  

  
  :root {
      --d: 1500ms;
      --angle: 90deg;
      --gradX: 100%;
      --gradY: 50%;
      --c1: rgba(168, 239, 255, 1);
      --c2: rgba(168, 239, 255, 0.1);
  }
  

  
  .service-tab:hover {
    border: 0.2rem solid;
    border-radius: 420deg;
    border-image: conic-gradient(from var(--angle), var(--c2), var(--c1) 0.1turn, var(--c1) 0.15turn, var(--c2) 0.25turn) 15;
    animation: borderRotate var(--d) linear infinite forwards;
  }

  @keyframes borderRotate {
      100% {
          --angle: 420deg;
      }
  }
  
  @keyframes borderRadial {
      20% {
          --gradX: 100%;
          --gradY: 50%;
      }
      40% {
          --gradX: 100%;
          --gradY: 100%;
      }
      60% {
          --gradX: 50%;
          --gradY: 100%;
      }
      80% {
          --gradX: 0%;
          --gradY: 50%;
      }
      100% {
          --gradX: 50%;
          --gradY: 0%;
      }
  }
  .service-container{
    min-height: 100vh;
  }
