.background {
  z-index: -1;
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0;
  padding: 0;
  background: #0066ff;
  overflow: hidden;

  .particles {
    position: absolute;
    inset: 0;
    z-index: 0;
  }
}
